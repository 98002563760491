import styled from "styled-components"

export const FullscreenMap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gray);
  z-index: 99;
`

export const FullscreenButtonClose = styled.button`
  position: absolute;
  z-index: 999;
  top: 90px;
  left: 30px;
`
