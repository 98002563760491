import styled from "styled-components"

export const TrailSetNav = styled.div`
  padding: 10px;
  border-bottom: 2px solid #e9e9e9;
  background-color: white;

  ul {
    max-width: ${(props) => props.theme.sizes.containerWidth};
    margin: auto;
    display: flex;
    justify-content: center;
    cursor: pointer;

    li {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      margin: 0 0.5rem;      
      transition: color 0.2s ease;
      max-width: 8rem;

      svg {
        padding: 0 0.5rem 0.5rem;
      }

      &:not(.active) {
        color: ${props => props.theme.colors.grayDarker};

        svg path {
          fill: ${props => props.theme.colors.grayDarker};
          transition: fill 0.2s ease;
        }
      }

      &:hover:not(.active) {
        color: ${props => props.theme.colors.black};

        svg path {
          fill: ${props => props.theme.colors.black};
        }
      }
    }
  }
`

export const TrailSetNavTitle = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
`

export const Main = styled.main``

export const TrailsConntainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: ${(props) => props.theme.sizes.containerWidth};
  margin: 2rem auto;

  > div:nth-last-child(n + 2) {
    ${(props) => props.theme.media.smDown} {
      width: 100%;
      border-bottom: 2px solid ${(props) => props.theme.colors.gray};
    }
  }
`

export const TrailCard = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  ${(props) => props.theme.media.mdUp} {
    width: 440px;
  }
`

export const TrailCardImage = styled.div<{
  background: string
  maskImage: string
}>`
  width: 22.5rem;
  height: 14rem;
  margin: 2rem auto;
  background: url(${(props) => props.background}) no-repeat center center /
    contain;
  -webking-mask-image: url('${(props) => props.maskImage}');
  mask-image: url('${(props) => props.maskImage}');
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;

  &:hover {
    mask-image: none;
    border-radius: 0.75rem;
  }
`

export const TrailCardTitle = styled.div`
  margin: 0 2rem;
  flex-grow: 1;
  font-family: "Suisseintl";
  font-weight: 700;
  font-size: 2.5rem;
`

export const TrailCardLink = styled.p`
  margin: 1rem 2rem 2rem 2rem;
`

export const IconArrow = styled.img`
  width: 27px;
  height: 22px;
  margin-left: 10px;
`
