import React, { useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { useLocal } from "../../contexts/LocalContext"
import { Button, TextField, FormHelperText } from "@material-ui/core"
import Modal from "../../primitives/modal"
import strToUrl from "../../helpers/strToUrl"
import * as S from "./styles"

export default function ForgotPassword() {
  const { resetPassword } = useAuth()
  const { trails, webpSupport } = useLocal()
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")

  useEffect(() => {}, [trails])

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setSuccess("")
      setError("")
      setLoading(true)
      await resetPassword(email)
      setSuccess("Na e-mail jsme Ti poslali instrukce pro obnovení hesla")
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setError("Uživatel s tímto e-mailem neexistuje")
      } else {
        setError("Nepodařilo se obnovit heslo")
      }
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <>
      <Modal visible={true} type="primary" maxWidth={100}>
        <form onSubmit={handleSubmit}>
          <S.ModalTitle>Zapomenuté heslo</S.ModalTitle>
          <S.TextFieldWrapper>
            <TextField
              label="E-mail"
              required
              variant="outlined"
              style={{ width: 300 }}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />
            <FormHelperText id="details-helper-text">
              Na e-mail ti pošleme instrukce k obnovení hesla
            </FormHelperText>
          </S.TextFieldWrapper>
          <S.ButtonWrapper>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
              className="main-button"
              style={{ width: 300 }}
            >
              Obnovit heslo
            </Button>
          </S.ButtonWrapper>
          <S.P>
            Máš už účet? <S.Link to="/login">Přihlas se</S.Link>
          </S.P>
          <S.P>
            Ještě nemáš účet? <S.Link to="/signup">Zaregistruj se</S.Link>
          </S.P>
        </form>
      </Modal>
      <main>
        <S.TrailsConntainer>
          {trails.map((trail, index) => (
            <S.TrailCard key={index}>
              <S.TrailCardImage
                background={`/images/trails/${strToUrl(trail.name)}_720${
                  webpSupport ? ".webp" : ".jpg"
                }`}
                maskImage={`/images/trails/${strToUrl(trail.name)}.svg`}
              />
              <S.TrailCardTitle>
                {trail.name}
                <S.IconArrow src="/images/arrow_right.svg" alt="profile" />
              </S.TrailCardTitle>
              <S.TrailCardLink>popis trasy na ultreia.cz</S.TrailCardLink>
            </S.TrailCard>
          ))}
        </S.TrailsConntainer>
      </main>
      {error !== "" && (
        <Modal
          visible={error !== ""}
          topMargin={5}
          autoHide={3}
          toggle={() => setError("")}
          hideBackdrop={true}
        >
          {error}
        </Modal>
      )}
      {success !== "" && (
        <Modal
          visible={success !== ""}
          autoHide={3}
          toggle={() => setSuccess("")}
          hideBackdrop={true}
          topMargin={5}
          type="success"
        >
          {success}
        </Modal>
      )}
    </>
  )
}
