import React, { useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { useDb } from "../../contexts/DbContext"
import { useHistory, Link, useParams } from "react-router-dom"
import ModalPhoneUpdate from "../../primitives/modal-phone-update"
import Modal from "../../primitives/modal"
import strToUrl from "../../helpers/strToUrl"
import numberToLocalString from "../../helpers/numberToLocalString"
import * as S from "./styles"
import {
  Map,
  MarkerLayer,
  Marker,
  PathLayer,
  Path,
  MouseControl,
  KeyboardControl,
  ZoomControl,
} from "react-mapycz"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import FullScreenMap from "./full-screen-map"
import getTrailMap from "../../helpers/getTrailMap"
import Chip from "../../components/Chip"
import Button from "../../components/Button"
import {
  family_restroom,
  help,
  info,
  fork_right,
  notifications,
  hiking,
  kitchen,
  bungalow,
  arrow_right,
  arrow_left,
} from "../../svgs"
import { accomodationTypes } from "../host-profile"

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

export default function Place() {
  const history = useHistory()
  const { placeId, trailId } = useParams()
  const [error, setError] = useState("")
  const [place, setPlace] = useState({})
  const [trailNames, setTrailNames] = useState([])
  const [showContact, setShowContact] = useState(false)
  const [placeUser, setPlaceUser] = useState({})
  const [showWizard, setShowWizard] = useState(false)
  const [trailCoords, setTrailCoords] = useState([])
  const [fullscreenMap, setFullscreenMap] = useState(false)
  const { getPlace, getUser, getTrailName } = useDb()
  const { currentUser } = useAuth()

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    // Get Place details
    getPlace(placeId)
      .then((querySnapshot) => {
        if (querySnapshot.exists) {
          setPlace(querySnapshot.data())
          // Get place user's data
          if (currentUser.phoneNumber) {
            getUser(querySnapshot.data().uid).then((querySnapshot) => {
              if (querySnapshot.exists) {
                setPlaceUser(querySnapshot.data())
              }
            })
          }
        } else {
          setError("Místo neexistuje")
        }
      })
      .catch((error) => {
        setError("Nepodařílo se otevřít dokument:", error)
      })
    return () => {
      setPlace({})
      setPlaceUser({})
    }
  }, [getPlace, getUser, placeId, getTrailName, currentUser])

  useEffect(() => {
    ; (async () => {
      if (trailId) {
        try {
          const coords = await getTrailMap(strToUrl(trailId))
          setTrailCoords(coords)
        } catch (error) {
          setError("Trasa neexistuje")
        }
      }
    })()
  }, [trailId])

  useEffect(() => {
    ; (async () => {
      if (place.trails) {
        let trailNamesTemp = []
        for (const trail of place.trails) {
          trailNamesTemp.push(await getTrailName(trail))
        }
        setTrailNames(trailNamesTemp)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place.trails])

  const handleShowContact = () => {
    // Only user with verified phone number can view contact deatils
    if (currentUser.phoneNumber) {
      setShowContact(true)
    }
  }

  return (
    <>
      <ModalPhoneUpdate
        visible={showWizard}
        close={() => setShowWizard(false)}
      />
      <Modal visible={error !== ""} autoHide={5} toggle={() => setError("")}>
        {error}
      </Modal>
      {fullscreenMap ? (
        <FullScreenMap
          onClick={() => setFullscreenMap(false)}
          place={place}
          trailCoords={trailCoords}
        />
      ) : (
        <>
          <S.ImageContainer>
            <Carousel
              showStatus={false}
              dynamicHeight={false}
              showThumbs={false}
              showIndicators={place?.images && place?.images.length > 1}
              infiniteLoop={true}
            >
              {place?.images &&
                place.images.map((image, key) => (
                  <div key={"image " + key}>
                    <S.Image
                      src={"https://" + image}
                      key={key}
                      alt={"image " + key}
                    />
                  </div>
                ))}
            </Carousel>
          </S.ImageContainer>
          <S.Main>
            <S.Title>{place.name}</S.Title>
            {trailNames &&
              Array.isArray(trailNames) &&
              trailNames.map((trail) => (
                <span key={strToUrl(trail)}>
                  <Link to={strToUrl("/trail/" + trail)}>
                    {trail}
                  </Link>{" "}
                </span>
              ))}
            <S.Hr />
            {place.details && <S.PDetails>{place.details}</S.PDetails>}
            <ul>
              <S.ListItem>
                {family_restroom}
                <S.ListBody>
                  <S.ListValue>{place.capacity} hosté</S.ListValue>
                  <S.ListKey color="secondary">kapacita</S.ListKey>
                </S.ListBody>
              </S.ListItem>
              <S.ListItem>
                {help}
                <S.ListBody>
                  <S.ListValue>
                    {place.price &&
                      (place.price === -1
                        ? "Dobrovolný příspěvek"
                        : numberToLocalString(place.price) + " Kč")}
                  </S.ListValue>
                  <S.ListKey>cena</S.ListKey>
                </S.ListBody>
              </S.ListItem>
              {place.distance ? (
                <S.ListItem>
                  {fork_right}
                  <S.ListBody>
                    <S.ListValue>
                      {place.distances?.map((distance, index) => (
                        <div key={index}>
                          {distance > 5 ? (
                            <S.Red>{numberToLocalString(distance)} km</S.Red>
                          ) : (
                            <>{numberToLocalString(distance)} km</>
                          )}
                          <S.TrailName>({trailNames[index]})</S.TrailName>
                        </div>
                      ))}
                    </S.ListValue>
                    <S.ListKey>vzdálenost od trasy</S.ListKey>
                  </S.ListBody>
                </S.ListItem>
              ) : null}
              {place.type && (
                <S.ListItem>
                  {bungalow}
                  <S.ListBody>
                    <S.ListValue>
                      {
                        accomodationTypes.find(
                          (type) => type.textId === place.type
                        ).title
                      }
                    </S.ListValue>
                    <S.ListKey>typ ubytování</S.ListKey>
                  </S.ListBody>
                </S.ListItem>
              )}
              {place.checkin && (
                <S.ListItem>
                  {notifications}
                  <S.ListBody>
                    <S.ListValue>{place.checkin}</S.ListValue>
                    <S.ListKey>příchod</S.ListKey>
                  </S.ListBody>
                </S.ListItem>
              )}
              {place.checkout && (
                <S.ListItem>
                  {hiking}
                  <S.ListBody>
                    <S.ListValue>{place.checkout}</S.ListValue>
                    <S.ListKey>odchod</S.ListKey>
                  </S.ListBody>
                </S.ListItem>
              )}

              {place.facilities && Array.isArray(place.facilities) && (
                <S.ListItem>
                  <div>{kitchen}</div>
                  <S.ListBody>
                    <S.ListValue>
                      {place.facilities.map((facility, key) => (
                        <Chip key={key} content={facility} color="gray" />
                      ))}
                    </S.ListValue>
                    <S.ListKey>vybavení</S.ListKey>
                  </S.ListBody>
                </S.ListItem>
              )}
              {place.web && (
                <S.ListItem>
                  {info}
                  <S.ListBody>
                    <S.ListValue>
                      <a
                        href={"//" + place.web}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {place.web}
                      </a>
                    </S.ListValue>
                    <S.ListKey>Web</S.ListKey>
                  </S.ListBody>
                </S.ListItem>
              )}
            </ul>
            <S.Hr />
            {!showContact ? (
              <S.FormRow>
                {!currentUser.phoneNumber ? (
                  <>
                    <p>
                      Pro zobrazení kontaktu prosím ověř své telefonní číslo.
                    </p>
                    <Button onClick={() => setShowWizard(true)}>
                      Vyplň telefonní číslo
                      <S.ArrowRight>{arrow_right}</S.ArrowRight>
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={handleShowContact}
                    color="primary"
                    disabled={!currentUser.phoneNumber}
                  >
                    ukázat kontakt <S.ArrowRight>{arrow_right}</S.ArrowRight>
                  </Button>
                )}
              </S.FormRow>
            ) : (
              <>
                <S.DetailsList>
                  {placeUser.photoURL && (
                    <li>
                      <S.Avatar
                        src={placeUser.photoURL}
                        alt="Avatar"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = "/images/placeholder.png"
                        }}
                      />
                    </li>
                  )}
                  {placeUser.displayName && (
                    <li>
                      <S.Gray>jméno:</S.Gray> {placeUser.displayName}
                    </li>
                  )}
                  <li>
                    <S.Gray>telefon:</S.Gray> {placeUser.phoneNumber}
                  </li>
                  <li>
                    <S.Gray>e-mail:</S.Gray>{" "}
                    <a href={"mailto:" + placeUser.email}>{placeUser.email}</a>
                  </li>
                  {place.detailsPrivate && (
                    <li>
                      <S.Gray>doplňující kontaktní informace:</S.Gray>{" "}
                      {place.detailsPrivate}
                    </li>
                  )}
                  <li>
                    <S.Gray>adresa:</S.Gray> {place.address && place.address[0]}{" "}
                    {isMobile ? (
                      <a href={`geo:${place.address[2]},${place.address[3]}`}>
                        otevřít mapu v aplikaci
                      </a>
                    ) : (
                      <a
                        href={`https://mapy.cz/turisticka?x=${place.address[3]}&y=${place.address[2]}&z=17&source=coor&id=${place.address[3]}%2C${place.address[2]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        otevřít&nbsp;na&nbsp;Mapy.cz
                      </a>
                    )}
                  </li>
                </S.DetailsList>
                <S.MapContainer>
                  <Map
                    center={{
                      lat: place.address[2],
                      lng: place.address[3],
                    }}
                  >
                    <MouseControl zoom={true} pan={true} wheel={true} />
                    <KeyboardControl />
                    <ZoomControl />
                    <MarkerLayer>
                      <Marker
                        coords={{
                          lat: place.address[2],
                          lng: place.address[3],
                        }}
                      />
                    </MarkerLayer>
                    <PathLayer>
                      <Path coords={trailCoords} />
                    </PathLayer>
                  </Map>
                </S.MapContainer>
                <S.FullscreenOpen
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setFullscreenMap(true)
                  }}
                >
                  zobrazit mapu na celou obrazovku
                </S.FullscreenOpen>
              </>
            )}
            <Button
              onClick={() => history.push("/trail/" + strToUrl(trailId))}
              variant="outlined"
              color="gray"
            >
              <S.ArrowLeft>{arrow_left}</S.ArrowLeft> zpět na trasu
            </Button>
          </S.Main>
        </>
      )}
    </>
  )
}
