import styled from "styled-components"

export const Main = styled.main``

export const Banner = styled.div<{ image: string }>`
  padding: 1rem 1rem;
  min-height: 18rem;
  background: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${(props) => props.theme.media.smUp} {
    min-height: 25rem;
  }
`

export const TrailMap = styled.div<{ image: string }>`
  margin-left: 45vw;
  margin-bottom: 30vw;
  width: 12rem;
  height: 8rem;
  background: url(${(props) => props.image}) no-repeat;

  ${(props) => props.theme.media.smUp} {
    margin-bottom: 10rem;
    width: 18rem;
    height: 10rem;
  }
`

export const Container = styled.div`
  margin: auto;
  padding: 0 2rem;
  max-width: ${(props) => props.theme.sizes.containerWidth};
`

export const Title = styled.h1`
  margin-top: 2rem;
`

export const List = styled.ul``

export const ListItemMark = styled.li<{ borderBottom: boolean }>`
  width: 100%;
  display: flex;

  ${(props) =>
    props.borderBottom &&
    `border-bottom: 2px solid ${props.theme.colors.gray};`};
`

export const ListItem = styled.li<{ borderBottom: boolean }>`
  ${(props) =>
    props.borderBottom &&
    `border-bottom: 2px solid ${props.theme.colors.gray};
    padding-bottom: 0.5rem;`};
`

export const ListItemContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 -0.5rem;
  border-radius: 1rem;
  line-height: 1.5;

  &:hover {
    background: #efefef;
  }
`

export const MarkTitle = styled.div<{ bold: boolean }>`
  display: flex;
  width: calc(100vw - 4rem);
  text-overflow: ellipsis;
  overflow: hidden;
  ${(props) => props.bold && `font-weight: 700`};

  > .kms {
    display: inline-flex;
    justify-content: right;
    min-width: 5rem;
  }

  > .bullet {
    margin: 0 0.5rem;    
  }

  > .name {
    line-height: initial;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const PlaceImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const PlaceImage = styled.img`
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
  border-radius: 1rem;
`

export const PlaceName = styled.div`
  font-weight: bold;
  font-size: 1rem;
`

export const PlaceDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.9rem;

  > div {
    align-items: center;
    display: flex;
    line-height: 1.3;
  }

  svg {
    height: 0.75em;
  }
`
export const Red = styled.span`
  color: ${(props) => props.theme.colors.danger};
`
export const Gray = styled.span`
  color: ${(props) => props.theme.colors.grayDarker};
`
