import React from "react"
import Signup from "./pages/signup"
import { AuthProvider } from "./contexts/AuthContext"
import { DbProvider } from "./contexts/DbContext"
import { LocalProvider } from "./contexts/LocalContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles"
//import { csCZ } from "@mui/material/locale"
import theme from "./theme"
import Dashboard from "./pages/dashboard"
import Login from "./pages/login"
import PrivateRoute from "./pages/PrivateRoute"
import ForgotPassword from "./pages/forgot-password"
import UserProfile from "./pages/user-profile"
import HostProfile from "./pages/host-profile"
import Trail from "./pages/trail"
import Place from "./pages/place"
import Partners from "./pages/partners"
import Navigation from "./parts/navigation"
import Admin from "./pages/admin"
import Footer from "./parts/footer"
import * as S from "./App.styles"

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#CFB023",
      contrastText: "#212322"
    },
    warning: {
      main: "#ffaaaa",
    },
  },
  typography: {
    fontFamily: [
      'Suisseintl',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  //csCZ,
})

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <DbProvider>
          <LocalProvider>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={theme}>
                <S.Container>
                  <Navigation />
                  <Switch>
                    <PrivateRoute
                      path="/user-profile"
                      component={UserProfile}
                    />
                    <PrivateRoute
                      path="/host-profile"
                      component={HostProfile}
                    />
                    <PrivateRoute path="/trail" component={Trail} />
                    <PrivateRoute path="/place/:placeId/:trailId?" component={Place} />
                    <PrivateRoute path="/admin" component={Admin} />
                    <PrivateRoute path="/partners" component={Partners} />
                    <Route path="/signup" component={Signup} />
                    <Route path="/login" component={Login} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <PrivateRoute exact path="/:trailSetId?" component={Dashboard} />
                  </Switch>
                  <Footer />
                </S.Container>
              </ThemeProvider>
            </MuiThemeProvider>
          </LocalProvider>
        </DbProvider>
      </AuthProvider>
    </Router>
  )
}
