import React, { useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { useDb } from "../../contexts/DbContext"
import { useHistory } from "react-router-dom"
import { Button, makeStyles, Container } from "@material-ui/core"
import trailsJson from "../../admin/trails.json"

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: "0.5rem",
    marginTop: "1.5rem",
  },
  chip: {
    marginRight: "0.5rem",
  },
}))

export default function Place(props) {
  const history = useHistory()
  const [trailsImport, setTrailsImport] = useState([])
  const { importTrails } = useDb()
  const { currentUser } = useAuth()
  const classes = useStyles()

  useEffect(() => {
    var trailsFormated = Object.keys(trailsJson).map((trailName) => {
      var places = trailsJson[trailName].map((place) => {
        return [place.name, place.km]
      })
      return [trailName, places]
    })
    setTrailsImport(trailsFormated)
  }, [])

  const handleImportTrails = () => {
    importTrails(trailsImport)
    alert("Import zahájen")
  }

  return (
    <>
      {currentUser.email === "poooow@gmail.com" && (
        <Container>
          <Button
            onClick={() => handleImportTrails()}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Smazat stávající a vytvořit nová průchozí místa
          </Button>
          <h2>Data pro import:</h2>
          {trailsImport.map((trail, trailKey) => {
            return (
              <div key={trailKey}>
                <h2>{trail[0]}</h2>
                <table>
                  <tbody>
                    {trail[1].map((place, placeKey) => {
                      return (
                        <tr key={placeKey}>
                          <td>{place[0]}</td>
                          <td>{place[1]}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )
          })}
          <Button
            onClick={() => history.goBack()}
            variant="outlined"
            color="primary"
            type="submit"
            className={classes.button}
          >
            Zpět
          </Button>
        </Container>
      )}
    </>
  )
}
