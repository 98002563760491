import React from "react"
import {
  Map,
  MarkerLayer,
  Marker,
  PathLayer,
  Path,
  MouseControl,
  KeyboardControl,
  ZoomControl,
} from "react-mapycz"
import * as S from "./styles"

export default function FullScreenMap(props) {
  const { place, trailCoords, onClick } = props

  return (
    <S.FullscreenMap>
      <S.FullscreenButtonClose onClick={() => onClick(false)}>
        ✕ Zavřít mapu
      </S.FullscreenButtonClose>
      <Map
        height={window.innerHeight || 300}
        center={{
          lat: place.address[2],
          lng: place.address[3],
        }}
      >
        <MouseControl zoom={true} pan={true} wheel={true} />
        <KeyboardControl />
        <ZoomControl />
        <MarkerLayer>
          <Marker
            coords={{
              lat: place.address[2],
              lng: place.address[3],
            }}
          />
        </MarkerLayer>
        <PathLayer>
          <Path coords={trailCoords} />
        </PathLayer>
      </Map>
    </S.FullscreenMap>
  )
}
