import styled from "styled-components"

export const Container = styled.div``

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1150;
  background: rgba(0, 0, 0, 0.6);
`

export const Modal = styled.div<{
  type: string
  maxWidth: number
  topMargin: number
}>`
  position: fixed;
  display: block;
  z-index: 1200;
  left: 50%;
  ${(props) => props.topMargin ? `top: ${props.topMargin}rem;` : 'top: 50%;'}  
  transform: translate(-50%, -50%);
  width: 90%;

  @media (min-width: 576px) {
    width: auto;
  }

  ${(props) =>
    props.maxWidth ? `max-width: ${props.maxWidth}rem` : `max-width: 100%`};
  max-height: 90%;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.colors[props.type]};
  color: var(--${(props) => (props.type === "white" ? "black" : "white")});
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.15);
`

export const CloseButton = styled.div`
  position: absolute;
  z-index: 1;
  right: 20px;
  cursor: pointer;
`

export const ModalContent = styled.div<{ closable: boolean; padding: number }>`
  position: relative;
  top: 0;
  left: 0;
  max-height: 80vh;
  overflow: auto;
  padding: ${(props) => props.padding}px
    ${(props) => (props.closable ? props.padding + 30 : props.padding)}px
    ${(props) => props.padding}px ${(props) => props.padding}px;
  scrollbar-width: thin;
`
