import styled from "styled-components"

export const Footer = styled.footer<{blur: boolean}>`
  ${props => props.blur && `filter: blur(10px);`}
  padding: 1rem;
  margin-top: 2rem;
  background-color: ${(props) => props.theme.colors.grayLight};
  border-top: 2px solid ${(props) => props.theme.colors.gray};
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${(props) => props.theme.media.smDown} {
    flex-direction: column;
  }
`

export const Section = styled.div`
  margin: 0 2rem;

  ${(props) => props.theme.media.smDown} {
    border-bottom: 2px solid ${(props) => props.theme.colors.gray};
  }
`

export const List = styled.ul`
  margin-top: 0;
  list-style-type: none;
  line-height: 1.2;

  li {
    line-height: 1.5;

    ul {
      padding-left: 1rem;
    }
  }
`

export const Title = styled.h4`
  margin-bottom: 0.5rem;
`

export const Link = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.colors.black};
  text-decoration: underline;
`

export const Logo = styled.div`
  margin: 2rem;

  > img {
    width: 5rem;
    height: auto;
    filter: grayscale(100%);
  }

  ${(props) => props.theme.media.smUp} {
    text-align: center;
  }
`
